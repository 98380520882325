<template>
	<div :style="buyBg">
		<Header :title="$t('assets.buyHkForSelf')"></Header>
	
		<div class="column-center-content main-content">
			<div class="row-content banlance-content">
				<label style="color: #FF0000; font-weight: bold">{{$t('common.usdm')}}:</label>
				<label style="color: #FF0000; margin-left: 5px;" v-text="usdm"></label>
			</div>
			
			<div class="row-content shadow-box-content buy-box">
				<div class="column-center-content">
					<img src="../../../../assets/home/buy/moneybag.svg" class="type-img" style="margin-top: 10px;"/>
					<label style="color: #FFFFFF;">{{$t('common.usdm')}}</label>
				</div>
				<i class="fa fa-arrow-right" aria-hidden="true" style="color: #FFFFFF;"></i>
				<div class="column-center-content">
					<img src="../../../../assets/home/buy/stock-logo.png" class="type-img"/>
					<label style="color: #FFFFFF;">{{$t('common.hk')}}</label>
				</div>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.buyHkAmount')}}</label>
				<v-text-field type="number" :placeholder="$t('assets.buyHkAmount_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="hkAmount"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.needUsdm')}}</label>
				<v-text-field readonly :placeholder="$t('assets.needUsdm_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="usdmAmount"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.payPwd')}}</label>
				<v-text-field :placeholder="$t('assets.payPwd_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="pwd" type="password"></v-text-field>
			</div>
			
			<div class="row-content form-content" style="justify-content: flex-start;">
				<label class="form-title">{{$t('common.usdm')}}：</label>
				<label class="form-title" v-text="usdmAmount"></label>
			</div>
			
			<div class="btn-content">
				<v-btn rounded block @click="showConfirmTip" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="confirmTipVisible" popup-transition="popup-fade">
			<BuyConfirmTip :tipInfo="tipInfo"></BuyConfirmTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="successTipVisible" popup-transition="popup-fade">
			<BuySuccessTip :tipInfo="tipInfo"></BuySuccessTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../../components/NavHeader.vue'
	import BuyConfirmTip from '../../../../components/Usdm_BuyConfirmTip.vue'
	import BuySuccessTip from '../../../../components/Usdm_BuySuccessTip.vue'
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'BuyHkForSelfWithUsdm',
		components: {
			Header,
			BuyConfirmTip,
			BuySuccessTip
		},
		watch: {
			hkAmount:function (val) {
				this.usdmAmount = val * 3
			}
		},
		data() { 
			return {
				buyBg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				usdm: JSON.parse(localStorage.getItem('walletInfo')).rusdm ? JSON.parse(localStorage.getItem('walletInfo')).rusdm : 0,
				hkAmount: 0,
				ambcAmount: 0,
				pwd: '',
				usdmAmount: 0,
				confirmTipVisible: false,
				successTipVisible: false,
				tipInfo: {
					title: '',
					type: '',
					coinType: '',
					toUser: '',
					amount: 0,
					usdm: 0,
					ambc: 0
				}
			}
		},
		methods: {
			showConfirmTip() {
				
				let hk = this.$data.hkAmount;
				let usdm = this.$data.usdmAmount;
				let ambc = this.$data.ambcAmount;
				let pwd = this.$data.pwd;
				
				if(null == hk || '' == hk) {
					Toast({
						message: this.$i18n.t("assets.buyHkAmount_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(hk <= 0) {
					Toast({
						message: this.$i18n.t("assets.buyHkAmount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("assets.payPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == usdm || '' == usdm) {
					usdm = 0;
				}
				if(null == ambc || '' == ambc) {
					ambc = 0;
				}
				
				this.$data.tipInfo = {
					title: 'assets.buyHkForSelf',
					type: 'self',
					coinType: 'hk',
					toUser: '',
					amount: hk,
					usdm: usdm,
					ambc: ambc
				}
				this.$data.confirmTipVisible = true;
			},
			
			closePop() {
				this.$data.confirmTipVisible = false;
				this.$data.successTipVisible = false;
			},
			
			buy() {
				this.$data.confirmTipVisible = false;
				
				let hk = this.$data.hkAmount;
				let usdm = this.$data.usdmAmount;
				let ambc = this.$data.ambcAmount;
				let pwd = this.$data.pwd;
				
				if(null == usdm || '' == usdm) {
					usdm = 0;
				}
				if(null == ambc || '' == ambc) {
					ambc = 0;
				}
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				let params = {
					amount: hk,
					usdm: usdm,
					ambc: ambc,
					production: 'hk',
					target: '',
					pwd: pwd
				}
				
				this.$request.tradeUsdm(
					params
				).then(()=>{
					Indicator.close();
					sessionStorage.setItem('refreshInfo', '1');
					this.$data.tipInfo = {
						title: 'assets.buyHkForSelf',
						type: 'self',
						coinType: 'hk',
						toUser: '',
						amount: hk,
						usdm: usdm,
						ambc: ambc
					}
					this.$data.successTipVisible = true;
					
				});
			},
			
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
	.buy-box {
		width: 90%; 
		height: 140px; 
		background-color: #fd5424; 
		padding: 10px 10%; 
		justify-content: space-between;
	}
	
	.type-img {
		width: auto; 
		height: 50px; 
		margin-bottom: 10px;
	}
</style>
